import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FooterWrapper, FooterColumn } from './Footer.styled';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const sendEmail = () => {
  const url = window.location;
  url.href = 'mailto:info@optimum-filter.com';
};

const Footer = () => {
  const { t } = useTranslation();
  return (
    <FooterWrapper>
      <Container>
        <Row>
          <Col>
            <FooterColumn>
              <h3 className="title title-contact">{t('footer.get_in_touch')}</h3>
              <div className="content">
                {t('footer.contact_via_email')}
                <a onClick={sendEmail} style={{ color: '#B59159' }}>
                  {t('footer.email')}
                </a>{' '}
                {t('footer.use_contact_menu')}
              </div>
              <br />
              <div className="content">
                © OptiFilter International Zrt. All rights reserved.
                <Link className="footer-link" to="/about-the-company">
                  {t('about_page.title')}
                </Link>
                <Link className="footer-link" to="/privacy-policy">
                  {t('footer.policy')}
                </Link>
              </div>
            </FooterColumn>
          </Col>
        </Row>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
