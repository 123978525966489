import styled from 'styled-components';

export const FormWrapper = styled.div`
  width: 100%;
  .form-group {
    margin: 30px 0;
    input[type='text'],
    input[type='email'] {
      background: transparent;
      border: none;
      border-bottom: 1px solid white;
      outline: none;
      color: white;
    }
    textarea {
      background: transparent;
      border: none;
      border-bottom: 1px solid white;
      outline: none;
      color: white;
    }
    label {
      font-weight: bold;
    }
  }
  .submit-button {
    background: transparent;
    border: 1px solid white;
    padding: 10px;
    color: white;
    text-transform: uppercase;
    transition: all 0.6s;
    &:hover {
      background: white;
      color: black;
      cursor: pointer;
    }
  }
  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    border-color: #dc3545 !important;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url(
      data:image/svg + xml,
      %3csvgxmlns='http://www.w3.org/2000/svg'viewBox='0 0 12 12'width='12'height='12'fill='none'stroke='%23dc3545'%3e%3ccirclecx='6'cy='6'r='4.5'/%3e%3cpathstroke-linejoin='round'd='M5.8 3.6h.4L6 6.5z'/%3e%3ccirclecx='6'cy='8.2'r='.6'fill='%23dc3545'stroke='none'/%3e%3c/svg%3e
    ));
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center !important;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
    label {
      color: red !important;
    }
  }
`;
