import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal } from 'react-bootstrap';
import { ContactWrapper } from './Contact.styled';
import ContactForm from '../Form/Form';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useMediaQuery } from 'react-responsive';

const Contact = ({ isModalShow, isModalHide }) => {
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const [modalShow, setModalShow] = useState(false);

  const handleClose = () => {
    setModalShow(false);
    isModalHide(false);
  };

  useEffect(() => {
    setModalShow(isModalShow);
  }, [isModalShow]);

  return (
    <ContactWrapper>
      <Modal show={modalShow} fullscreen={true} onHide={() => handleClose()}>
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body>
          <ContactWrapper>
            <div className="contact-close" onClick={handleClose}>
              <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M60 12L12 60" stroke="white" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
                <path d="M12 12L60 60" stroke="white" strokeWidth="3" strokeMiterlimit="10" strokeLinecap="round" />
              </svg>
            </div>
            <Row>
              <Col className={`p-0 ${isTabletOrMobile ? 'col-12' : ''}`}>
                <div className="contact__left">
                  <div className="content">
                    <h3 className="title">{t('contact.get_in_touch')}</h3>
                    <p>{t('contact.intro')}</p>
                  </div>
                </div>
              </Col>
              <Col className={`p-0 ${isTabletOrMobile ? 'col-12' : ''}`}>
                <div className="contact__right">
                  <div className="content">
                    <ContactForm />
                  </div>
                </div>
              </Col>
            </Row>
          </ContactWrapper>
        </Modal.Body>
      </Modal>
    </ContactWrapper>
  );
};

export default Contact;

Contact.propTypes = {
  isModalShow: PropTypes.bool,
};
