import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MenuWrapper, MobileMenuWrapper } from './Menu.styled';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Animated } from 'react-animated-css';
import { navigate } from '@reach/router';

const Menu = ({ showContact }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });
  const { languages, originalPath } = useI18next();
  const { t } = useTranslation();

  const handleSetShow = () => {
    showContact(true);
  };

  const menuButton = useRef();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState();
  const [scrolledItem, setScrolledItem] = useState();
  const [isSubPage, setIsSubPage] = useState();

  const handleMobileMenu = (e) => {
    menuButton.current.classList.toggle('opened');
    menuButton.current.setAttribute('aria-expanded', menuButton.current.classList.contains('opened'));
    setIsMobileMenuOpen((prevState) => (prevState = !prevState));
  };

  const handleScrollTo = (elementId) => {
    const element = document.getElementById(elementId);
    setIsMobileMenuOpen(false);
    menuButton.current.classList.remove('opened');
    setScrolledItem(elementId);
    !isSubPage ? scrollTo(element) : (window.location.href = `/#${elementId}`);
  };

  const scrollTo = (element) => {
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top:
        element === 'contributors'
          ? element.getBoundingClientRect().top + window.scrollY
          : element.getBoundingClientRect().top + window.scrollY - 80,
    });
  };

  // const isSubPage = () => {
  //   const page = document.getElementById('page');
  //   if (page) {
  //     console.log('subpage');
  //     return true;
  //   }
  // };

  useEffect(() => {
    if (window.location.hash === '#about-us') {
      const element = document.getElementById('about-us');
      setTimeout(() => {
        scrollTo(element);
      }, 500);
    } else if (window.location.hash === '#contributors') {
      const element = document.getElementById('contributors');
      setTimeout(() => {
        scrollTo(element);
      }, 500);
    } else if (window.location.hash === '#products') {
      const element = document.getElementById('products');
      setTimeout(() => {
        scrollTo(element);
      }, 500);
    } else if (window.location.hash === '#partners') {
      const element = document.getElementById('partners');
      setTimeout(() => {
        scrollTo(element);
      }, 500);
    }
    const page = document.getElementById('page');
    if (page) {
      console.log('subpage');
      setIsSubPage(true);
    } else {
      setIsSubPage(false);
    }
  }, []);

  return (
    <>
      {!isTabletOrMobile && (
        <MenuWrapper className="d-none d-md-flex">
          <ul className="menu">
            <li>
              {!isSubPage && (
                <AnchorLink offset="100" href="#about-us">
                  {t('navigation.about_us')}
                </AnchorLink>
              )}
              {isSubPage && <Link to="/#about-us">{t('navigation.about_us')}</Link>}
            </li>
            <li>
              {!isSubPage && (
                <AnchorLink offset="100" href="#contributors">
                  {t('navigation.contributors')}
                </AnchorLink>
              )}
              {isSubPage && <Link to="/#contributors">{t('navigation.contributors')}</Link>}
            </li>
            <li>
              {!isSubPage && (
                <AnchorLink offset="100" href="#products">
                  {t('navigation.products')}
                </AnchorLink>
              )}
              {isSubPage && <Link to="/#products">{t('navigation.products')}</Link>}
            </li>
            <li>
              {!isSubPage && (
                <AnchorLink offset="100" href="#partners">
                  {t('navigation.partners')}
                </AnchorLink>
              )}
              {isSubPage && <Link to="/#partners">{t('navigation.partners')}</Link>}
            </li>
            <li>
              <a onClick={handleSetShow}>{t('navigation.contact')}</a>
            </li>
            <li>
              <a href="/taddutyfree">TAD Duty Free</a>
            </li>
            <li className="language-select">
              <ul className="languages">
                {languages.map((lng) => (
                  <li className="language-element" key={lng}>
                    <Link activeClassName="active" to={originalPath} language={lng}>
                      {lng}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </MenuWrapper>
      )}
      {isTabletOrMobile && (
        <MobileMenuWrapper>
          <div className="mobile-menu">
            <div className="mobile-menu__icon">
              <button
                className="menu"
                ref={menuButton}
                onClick={handleMobileMenu}
                // onClick="this.classList.toggle('opened');this.setAttribute('aria-expanded', this.classList.contains('opened'))"
                aria-label="Main Menu"
              >
                <svg width="100" height="100" viewBox="0 0 100 100">
                  <path
                    className="line line1"
                    d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                  />
                  <path className="line line2" d="M 20,50 H 80" />
                  <path
                    className="line line3"
                    d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                  />
                </svg>
              </button>
            </div>
          </div>
          {isMobileMenuOpen && (
            <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={isMobileMenuOpen}>
              <div className="mobile-dropdown">
                <ul className="main-menu list-unstyled">
                  {isSubPage && (
                    <li>
                      <Link to="/">{t('navigation.home')}</Link>
                    </li>
                  )}
                  <li>
                    <a onClick={() => handleScrollTo('about-us')}>{t('navigation.about_us')}</a>
                  </li>
                  <li>
                    <a onClick={() => handleScrollTo('contributors')}>{t('navigation.contributors')}</a>
                  </li>
                  <li>
                    <a onClick={() => handleScrollTo('products')}>{t('navigation.products')}</a>
                  </li>
                  <li>
                    <a onClick={() => handleScrollTo('partners')}>{t('navigation.partners')}</a>
                  </li>
                  <li>
                    <a onClick={handleSetShow}>{t('navigation.contact')}</a>
                  </li>
                  <li>
                    <a href="/taddutyfree">TAD Duty Free</a>
                  </li>
                  <li className="language-select">
                    <ul className="languages languages-mobile">
                      {languages.map((lng) => (
                        <li className="language-element" key={lng}>
                          <Link activeClassName="active" to={originalPath} language={lng}>
                            {lng}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </div>
            </Animated>
          )}
        </MobileMenuWrapper>
      )}
    </>
  );
};

export default Menu;

Menu.propTypes = {
  showContact: PropTypes.func,
};
