import React from 'react';
import PropTypes from 'prop-types';
import { AgeVerificationWrapper } from './AgeVerification.styled';
import VideoBg from '../../../static/video_loop_2.mp4';
import VideoWebm from '../../../static/video_loop.webm';
import VideoPreload from '../../images/video_loop.webp';
import { useMediaQuery } from 'react-responsive';
import { StaticImage } from 'gatsby-plugin-image';

const AgeVerification = ({ handleAgeVerification, handleAgeVerificationDeclined }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1023px)' });
  return (
    <AgeVerificationWrapper>
      <div className="overlay">
        <div className="content">
          <h2 className="title">AGE VERIFICATION</h2>
          <p>
            At Optimum-Filter, we value our social responsibility and we need you to confirm your legal status before
            you proceed.
          </p>
          <button onClick={handleAgeVerification} className="age-button">
            I AM OF LEGAL AGE
          </button>
          <button onClick={handleAgeVerificationDeclined} className="age-button">
            I AM NOT OF LEGAL AGE
          </button>
          <p className="disclaimer">
            We use cookies to ensure that we give you the best experience on our website. By clicking on the button I am
            of legal age and entering the website you agree with our <a href="/privacy-policy">Privacy Policy</a>.
          </p>
        </div>
      </div>
      <div className="video">
        <div className="logo">
          <StaticImage placeholder="blurred" src="../../images/OF_white_gold.png" alt="Optimum-Filter" />
        </div>
        <video poster={VideoPreload} playsInline muted autoPlay loop>
          {/* <source src={VideoWebm} type="video/webm" /> */}
          <source src={VideoBg} type="video/mp4" />
        </video>
      </div>
    </AgeVerificationWrapper>
  );
};

export default AgeVerification;

AgeVerification.propTypes = {
  handleAgeVerification: PropTypes.func,
  handleAgeVerificationDeclined: PropTypes.func,
};
