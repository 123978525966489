import React from 'react';
import PropTypes from 'prop-types';
import { Primary } from './Layout.styled';
import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SEO from '../SEO/seo';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Link } from 'gatsby';

import CookieConsent from 'react-cookie-consent';

import './layout.css';

const Layout = ({ children, isSubpage }) => {
  const [selectedMenu, setSelectedMenu] = React.useState('home');
  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
        style={{ background: '#027083' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
      >
        We use our own cookies and third-party cookies so that we can show you this website and better understand how
        you use it, with a view to improving the services we offer. If you continue browsing, we consider that you have
        accepted the cookies.)
        <br />
        <Link style={{ color: '#C09756' }} to="/privacy-policy">
          Read more
        </Link>
      </CookieConsent>
      <SEO
        title="Optimum-Filter | Premium Cigarettes"
        description="Scientific experts with a biological scientific background or tobacco industry expertise have contributed to the development of the special cigarette filter."
        image="https://optimum-filter.com/__02_IMAGE/of_facebook.png"
      />
      {!isSubpage ? <Header /> : null}

      <Primary className={!isSubpage ? 'primary' : ''}>
        {React.Children.map(children, (child) => React.cloneElement(child, { selectedMenu }))}
      </Primary>
      {!isSubpage ? <Footer /> : null}
    </>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isSubpage: PropTypes.bool,
};
