import styled from 'styled-components';
import MailIcon from '../../../static/icon-mail.png';

export const FooterWrapper = styled.footer`
  padding: 25px;
  background: var(--blue);
  color: white;
`;

export const FooterColumn = styled.div`
  padding: 25px;
  a {
    color: var(--gold);
    opacity: 1;
    transition: all 1s;
    &:hover {
      color: var(--gold);
      opacity: 0.8;
    }
  }
  h3.title {
    color: var(--gold);
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 25px;
    &-contact {
      &:after {
        content: '';
        width: 26px;
        height: 18px;
        display: inline-block;
        background-image: url(${MailIcon});
        background-size: 26px 18px;
        margin: 0 0 -2px 10px;
      }
    }
  }
  ul {
    li {
      margin: 10px 0;
    }
  }
  .footer-link {
    margin: 0 2rem;
    @media screen and (max-width: 1023px) {
      display: block;
      margin: 2rem 0;
    }
  }
  a {
    cursor: pointer;
  }
`;
