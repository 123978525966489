import styled from 'styled-components';

export const LogoWrapper = styled.div`
  width: 188px;
  height: 48px;
  position: relative;
  display: flex;
  .st0 {
    fill: #fefefe;
  }
  .st1 {
    fill: #026f83;
  }
  .st2 {
    fill: #027083;
  }
`;
