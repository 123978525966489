import React, { useEffect, useState } from 'react';
import { HeaderWrapper } from './Header.styled';
import { Row, Col, Container, Modal } from 'react-bootstrap';
import Menu from '../Menu/Menu';
import Contact from '../Contact/Contact';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Logo from '../Logo/Logo';

const Header = () => {
  const [show, setShow] = useState(false);

  const headerShadow = () => {
    var header = document.getElementById('header');
    var sticky = header.offsetTop + 400;

    if (window.pageYOffset > sticky) {
      header.classList.add('add-shadow');
    } else {
      header.classList.remove('add-shadow');
    }
  };

  function handleSetShow() {
    setShow(true);
  }

  function handleClose() {
    setShow(false);
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const isSubPage = () => {
    let url = window.location.href;
    let splitUrl = url.split('/');
    if (splitUrl.length) {
      return true;
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', headerShadow);
  }, []);

  return (
    <>
      <Contact isModalShow={show} isModalHide={setShow} />
      <HeaderWrapper id="header">
        <Container>
          {isSubPage && (
            <Link to="/">
              {/* <StaticImage
                width={188}
                height={48}
                src="../../../static/OF_logo_R.png"
                placeholder="blurred"
                alt="Optimum-Filter"
              /> */}
              <Logo />
            </Link>
          )}
          {!isSubPage && (
            <div className="logo" onClick={scrollTop}>
              {/* <StaticImage
                width={188}
                height={48}
                src="../../../static/OF_logo_R.png"
                placeholder="blurred"
                alt="Optimum-Filter"
              /> */}
              <Logo />
            </div>
          )}

          <Menu showContact={setShow} />
        </Container>
      </HeaderWrapper>
    </>
  );
};

export default Header;
