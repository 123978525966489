import React, { useState, useRef } from 'react';
import { FormWrapper } from './Form.styled';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const API_PATH = 'https://optimum-filter.com/__01__API/index.php';

const ContactForm = () => {
  const { t } = useTranslation();
  const [mailSent, setMailSent] = useState();
  const [form, setForm] = useState();
  const [error, setError] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailInvalid, setEmailInvalid] = useState();
  const [nameInvalid, setNameInvalid] = useState();
  const [messageInvalid, setMessageInvalid] = useState();

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (email === '') {
      setEmailInvalid(true);
    } else {
      setEmailInvalid(false);
    }
    if (name === '') {
      setNameInvalid(true);
    } else {
      setNameInvalid(false);
    }
    if (message === '') {
      setMessageInvalid(true);
    } else {
      setMessageInvalid(false);
    }
    if (name && email && message) {
      console.log(`name: ${name}, email: ${email}, message: ${message}`);
      setMailSent(true);
      axios({
        method: 'post',
        url: `${API_PATH}`,
        headers: { 'Content-Type': 'application/json' },
        data: {
          name: name,
          email: email,
          message: message,
        },
      })
        .then((result) => {
          setMailSent(result.data.sent);
        })
        .catch((error) => {
          setError({ error: error.message });
          console.log(error.message);
        });
    }
  };

  return (
    <>
      {!mailSent && (
        <FormWrapper>
          <Form>
            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
              <Form.Label>{t('contact.your_name')}*</Form.Label>
              <Form.Control
                isInvalid={nameInvalid}
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="John Smith"
              />
            </Form.Group>
            <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
              <Form.Label>{t('contact.your_email')}*</Form.Label>
              <Form.Control
                isInvalid={emailInvalid}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="name@example.com"
              />
            </Form.Group>
            <Form.Group className="form-group" controlId="exampleForm.ControlTextarea1">
              <Form.Label>{t('contact.your_message')}*</Form.Label>
              <Form.Control
                isInvalid={messageInvalid}
                onChange={(e) => setMessage(e.target.value)}
                as="textarea"
                rows={3}
              />
            </Form.Group>
          </Form>
          <button onClick={(e) => handleFormSubmit(e)} type="submit" className="button submit-button">
            {t('contact.send')}
          </button>
        </FormWrapper>
      )}
      {mailSent && <div dangerouslySetInnerHTML={{ __html: t('contact.thanks') }}></div>}
    </>
  );
};

export default ContactForm;
