import React from 'react';
import { LogoWrapper } from './Logo.styled';
import LogoSVG from '../../images/OF_logo_R.svg';

const Logo = () => {
  return (
    <LogoWrapper>
      <img width="188" height="48" src={LogoSVG} alt="Optimum-Filter logo" />
    </LogoWrapper>
  );
};

export default Logo;
