import styled from 'styled-components';

export const ContactWrapper = styled.div`
  height: 100%;
  @media screen and (max-width: 1224px) {
    overflow: hidden;
  }
  .row {
    height: 100%;
    color: white;
  }
  .contact__left {
    background: var(--blue);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 150px;
    @media screen and (max-width: 1224px) {
      padding: 0 25px;
    }
    .title {
      font-family: ivypresto-display, serif;
      font-weight: 700;
      font-style: normal;
      font-size: 38px;
    }
  }
  .contact__right {
    background: var(--darkteal);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 150px;
    @media screen and (max-width: 1224px) {
      padding: 0 25px;
    }
    .content {
      width: 100%;
    }
  }
  .contact-close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    @media screen and (max-width: 1224px) {
      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
`;
