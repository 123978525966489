import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  height: var(--header-height);
  width: 100%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.07);
  position: fixed;
  z-index: 5;
  top: 0;
  transition: all 1s;
  &.add-shadow {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.2);
    height: calc(var(--header-height) - 20px);
    .logo {
      img {
        transition: all 1s;
        height: 40px;
        cursor: pointer;
      }
    }
  }
  .container {
    display: flex;
    align-items: center;
  }
  .logo {
    flex: 1; /* shorthand for: flex-grow: 1, flex-shrink: 1, flex-basis: 0 */
    display: flex;
    justify-content: flex-start;
    img {
      transition: all 1s;
      height: 48px;
    }
  }
`;
