import styled from 'styled-components';

export const MenuWrapper = styled.nav`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  font-weight: 700;
  ul.menu {
    list-style: none;
    margin: 0;
    li {
      margin: 0 10px;
      display: inline-block;
      a {
        color: var(--highlight);
        cursor: pointer;
        transition: all 0.6s;
        &:hover {
          color: #1a4b54;
        }
      }
      &.language-select {
        ul {
          padding: 0 !important;
          margin: 0;
          li {
            text-transform: uppercase;
            margin: 0 10px;
            a {
              opacity: 0.4;
              &.active {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  .language-element {
    font-weight: 100;
    a.active {
      font-weight: 700;
    }
  }
`;

export const MobileMenuWrapper = styled.div`
  margin-left: auto;
  .mobile-dropdown {
    background: white;
    position: absolute;
    width: 100%;
    padding: 3rem 2rem 2rem 2rem;
    left: 0;
    top: calc(var(--header-height) - 1.25rem);
    text-align: center;
    box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.07);
    .main-menu {
      margin: 0;
      padding: 0;
      li {
        margin: 2rem 0;
        font-size: 1.25rem;
        a {
          color: var(--highlight);
        }
      }
    }
  }
  .menu {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
    width: 50px;
  }
  .line {
    fill: none;
    stroke: #056f84;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
  }
  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
  .opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
  }
  .opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
  .languages-mobile {
    margin: 0;
    padding: 0;
    margin-top: 50px !important;
    li {
      list-style: none;
      display: inline-block;
      margin: 0 10px !important;
      a {
        font-weight: 100;
        &.active {
          font-weight: 700;
        }
      }
    }
  }
`;
