import styled, { createGlobalStyle } from 'styled-components';

export const Primary = styled.main`
  padding: 0;
`;

export const GlobalStyles = createGlobalStyle`
 
{
  @import url("https://use.typekit.net/xro2fcs.css");
  :root {
    --highlight: #027084;
    --blue: #01396F;
    --darkteal: #015C6B;
    --header: #027084;
    --header-height: 5rem;
    --gold: #BF9657;
    --text: #4D4A4E;
    --slider-height: 400px;
    --slider-negative-margin: 150px;
    --tad-brown: #69443C;
    --tad-green: #104510;
  }
  
  body {
    //font-family: 'Bodoni Moda', serif !important;
    //color: #333333;
    font-family: 'Poppins', sans-serif;
  }
  a {
    color: var(--highlight); 
    text-decoration: none !important;
  }
  .primary {
    margin-top: var(--header-height);
  }
  .modal-body {
    padding: 0;
  }
  section {
    margin: 12rem 0;
    @media screen and (max-width: 576px) {
      margin: 4rem;
    }
  }
  button {
    text-transform: uppercase !important;
    font-size: 1.325rem;
    letter-spacing: 3px;
    &.button-primary {
      border: 2px solid var(--gold);
      color: var(--gold);
      padding: 10px 35px;
      text-align: center;
      background: transparent;
      transition: all 0.7s;
      &:hover {
        background: var(--gold);
        color: white;
      }
      &--white {
        border: 2px solid white;
        color: white;
        background: transparent;
        &:hover {
          background: white;
          color: black;
        }
      }
    }
  }
  .main-title {
    font-family: ivypresto-display, serif;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 4px;
    &--blue {
      color: var(--header);
    }
  }

  .slick-track {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-dots li.slick-active button:before,
.slick-dots li button:before {
  color: white;
  font-size: 1rem;
}

.parallax-inner {
  display: flex;
  justify-content: flex-end;
}

.slider-pattern {
  z-index: 2;
  .pattern {
    width: 100% !important;
    height: 70% !important;
  }
}
  
}
`;
