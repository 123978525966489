import styled from 'styled-components';

export const AgeVerificationWrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 99;
  height: 100%;
  @media screen and (max-width: 1224px) {
    width: 100%;
    text-align: center;
  }
  .overlay {
    position: absolute;
    width: 30%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 2rem;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    z-index: 2;
    @media screen and (max-width: 1224px) {
      width: 100%;
    }
    .content {
      @media screen and (max-width: 1224px) {
        font-size: 0.875rem;
      }
      p {
        margin: 2rem 0;
        &.disclaimer {
          @media screen and (max-width: 1224px) {
            font-size: 0.675rem;
          }
        }
      }
      button {
        padding: 0.5rem 0;
        width: 100%;
        background: transparent;
        border: 2px solid white;
        color: white;
        margin: 1rem 0;
        cursor: pointer;
        transition: all 0.7s;
        font-size: 1rem;
        &:hover {
          background: white;
          color: black;
        }
      }
    }
  }
  .video {
    height: 100vh;
    position: relative;
    .logo {
      position: absolute;
      width: 70%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    video {
      width: 100vw;
      height: 100%;
      object-fit: cover;
      @media screen and (max-width: 1224px) {
        height: 100vh;
        width: auto;
      }
    }
  }
`;
